import create from 'zustand'
import {
  fetchTranslation,
  fetchProjects,
  patchTranslation,
  postNewTranslation,
  publish,
  reject,
  approve,
  patchValue,
  notify,
} from '../api/apiCalls'
import { Project, Display, Translation, Language, Settings } from '../types'
import { createTreeObject } from '../utils'

export const useTranslationDataStore = create<TranslationState>((set, get) => ({
  projects: [],
  error: undefined,
  projectSelected: null,
  display: 'all',
  isAddString: false,
  isPublish: false,
  translationTree: null,
  translation: null,
  isChanged: false,
  settings: { isDark: true, languageSelected: [] },
  selection: '',
  search: '',
  showNotify: false,
  changeTranslation: async (projectID, translationID, lng, value) => {
    const res = await patchTranslation(projectID, translationID, lng, value)
    if (res instanceof Error) {
      set({ error: res })
    } else {
      get().addTranslation(res)
    }
  },
  changeValue: async translation => {
    const res = await patchValue(translation)
    if (res instanceof Error) {
      set({ error: res })
    } else {
      get().addTranslation(res)
    }
  },

  fetchTranslation: async projectID => {
    const translation = await fetchTranslation(projectID)
    if (translation instanceof Error) {
      set({ error: translation })
      return
    }
    const translationTree = createTreeObject(translation)
    set({ translation, translationTree })
  },

  addTranslation: item => {
    let translation = get().translation
    if (!translation) return
    translation = [...translation.filter(({ id }) => id !== item.id), item]
    const translationTree = createTreeObject(translation)
    set({ translation, translationTree, showNotify: true })
  },

  fetchTranslationTree: () => {
    const translation = get().translation
    if (translation) {
      set({ translationTree: createTreeObject(translation) })
    }
  },

  postNew: async translation => {
    const res = await postNewTranslation(translation)
    if (res instanceof Error) {
      set({ error: res })
      return
    }
    get().addTranslation(res)
    set(state => ({ isAddString: !state.isAddString }))
  },
  publish: async (version, projectID) => {
    const res = await publish(version, projectID)
    if (res instanceof Error) {
      set({ error: res })
      return
    }
    set(state => ({ isPublish: !state.isPublish }))
  },

  selectLanguage: (language, isSelect) => {
    const settings = get().settings

    settings.languageSelected = isSelect
      ? [...settings.languageSelected, language]
      : settings.languageSelected.filter(it => it != language)

    set({ settings })
  },

  setDisplay: display => set({ display }),
  setSearch: search => set({ search }),
  setSelection: selection => set({ selection }),

  selectProject: project => {
    if (project) get().fetchTranslation(project.id)
    set(() => ({ projectSelected: project }))
  },

  fetchProjects: async () => {
    const projects = await fetchProjects()
    if (projects instanceof Error) {
      set({ error: projects })
      return
    }
    set({ projects })
  },

  toggleAddString: () => set(state => ({ isAddString: !state.isAddString })),
  togglePublish: () => set(state => ({ isPublish: !state.isPublish })),

  reset: () => {
    const { isDark } = get().settings
    set({
      projects: [],
      projectSelected: null,
      isAddString: false,
      translationTree: null,
      translation: null,
      isChanged: false,
      settings: { isDark, languageSelected: [] },
      selection: '',
      search: '',
      display: 'all',
    })
  },

  setIsDark: isDark => {
    const settings = { ...get().settings, isDark }
    set({ settings })
  },

  dismissError: () => {
    set({ error: undefined })
  },
  approve: async (id, projectID, value) => {
    const res = await approve(id, value)
    if (res instanceof Error) {
      set({ error: res })
    } else {
      get().addTranslation(res)
    }
  },
  reject: async (id, projectID, value) => {
    const res = await reject(id, value)
    if (res instanceof Error) {
      set({ error: res })
    } else {
      get().addTranslation(res)
    }
  },
  notify: async projectID => {
    const res = await notify(projectID)
    if (res instanceof Error) set({ error: res })
  },
  set: set,
}))

interface TranslationState {
  projects: Project[]
  error: Error | undefined
  projectSelected: Project | null
  display: Display
  isAddString: boolean
  isPublish: boolean
  translationTree: Record<string, unknown> | null
  translation: Translation[] | null
  isChanged: boolean
  settings: Settings
  selection: string
  search: string
  showNotify: boolean
  changeTranslation: (
    projectID: number,
    translationID: number,
    lng: string,
    value: string,
  ) => void
  changeValue: (translation: Translation) => void
  fetchTranslation: (projectID: number) => void
  addTranslation: (item: Translation) => void
  fetchTranslationTree: () => void
  postNew: (translation: Record<string, any>) => void
  publish: (version: string, projectID: number) => void
  setSelection: (selection: string) => void
  selectLanguage: (language: Language, isSelect: boolean) => void
  setDisplay: (display: Display) => void
  selectProject: (project: Project | null) => void
  fetchProjects: () => void
  setSearch: (string: string) => void
  toggleAddString: () => void
  togglePublish: () => void
  reset: () => void
  setIsDark: (isDark: boolean) => void
  dismissError: () => void
  approve: (id: number, projectID: number, value: string) => void
  reject: (id: number, projectID: number, value: string) => void
  notify: (projectID: number) => void
  set: any
}
