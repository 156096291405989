import React from 'react'
import { Project } from '../../types'
import { useTranslationDataStore } from '../../store'

interface Props {
  project: Project
}

export const ProjectTile = (props: Props) => {
  const { name, desc } = props.project
  const selectProject = useTranslationDataStore(state => state.selectProject)
  return (
    <div
      className="flex flex-col dark:bg-gray-700 bg-gray-400 rounded-lg p-5 cursor-pointer dark:hover:bg-black hover:bg-gray-200 transition justify-center items-center"
      onClick={() => selectProject(props.project)}>
      <div className="dark:text-gray-100 text-2xl border-b pb-2 mb-2 border-gray-500 w-full text-center">
        {name}
      </div>
      <div className="dark:text-gray-100">{desc}</div>
    </div>
  )
}
