import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'

export const API_ENV = import.meta.env.VITE_API_ENV
export const API_ENDPOINT = `https://api-${API_ENV}-v3.casenio.de`

if (process.env.NODE_ENV !== 'test') console.log(import.meta.env.VITE_API_ENV)

const options = {
  transports: ['websocket'],
  forceNew: true,
  timeout: 10000,
}

const socket = io(API_ENDPOINT)

export const app = feathers()
  .configure(socketio(socket, options))
  .configure(auth({ storage: localStorage }))

export const ACCOUNTS = app.service('accounts')
export const OEM = app.service('oem')
export const PROJECTS = app.service('i18n-projects')
export const TRANSLATIONS = app.service('i18n-translations')
export const COMM_METHODS = app.service('comm-methods')
export const USER_METHODS = app.service('user-methods')
export const GENERATE = app.service('generate-translation')
export const HISTORY = app.service('i18n-history')
