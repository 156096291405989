import React from 'react'
import { Button } from './Button'
import { useTranslationDataStore } from '../../store'

interface Props {
  error: Error
}

export const Error = (props: Props) => {
  const { dismissError } = useTranslationDataStore()
  return (
    <div className="absolute flex justify-center items-center w-screen h-screen bg-black bg-opacity-70 top-0 left-0">
      <div className="bg-white p-3 rounded px-20 ">
        <div className="text-center text-4xl border-b">Error!</div>
        <div className="my-3" data-testid="error-message">
          {props.error.message}
        </div>
        <Button title="Dismiss" onClick={dismissError} />
      </div>
    </div>
  )
}
