export enum EmailState {
  MISSING,
  PENDING,
  DONE,
}

export enum Language {
  'german',
  'english',
  'dutch',
  'russian',
  'danish',
  'romanian',
}

export type Display = 'all' | 'pending' | 'missing'

export interface Settings {
  isDark: boolean
  languageSelected: Language[]
}

export interface Project {
  id: number
  name: string
  languages: Language[]
  desc: string
}

export interface Translation {
  id: number
  projectID: number
  key: string
  value: string
  type: string | null
  static: string | null
  context: string | null
  en: string | null
  nl: string | null
  ru: string | null
  ro: string | null
  dk: string | null
  history: {
    en?: HistoryItem[]
    nl?: HistoryItem[]
    ru?: HistoryItem[]
    ro?: HistoryItem[]
    dk?: HistoryItem[]
  }
}

export interface HistoryItem {
  id: number
  value: string
  updated: string
  state: 0 | 1 | 2
  comment: string | null
  previousValue: string
}

export interface User {
  avatar?: string
  firstName?: string
  fk_accounts: number
  id: number
  lastName?: string
  name: string
  profileID?: number
  rights: { [key: string]: number }
  role: number
  settings?: Settings
  status: number
  username: string
}

export interface AuthPayload {
  username: string
  password: string
}
