import React from 'react'
import _ from 'lodash'
import { HistoryItem } from '../../types'
import { stringToFlag } from './../../utils'

export const History = (props: Props) => {
  const { history } = props
  const languages = Object.keys(history)

  if (_.isEmpty(history)) return <div>No history yet</div>
  return (
    <div>
      <div className="flex flex-col">
        {languages.map(it => (
          <div className="flex" key={`${it}`}>
            <img src={`/${stringToFlag(it)}`} className="m-3 rounded w-10" />
            <div className="ml-8 m-3 flex flex-col">
              {[...history[it]].reverse().map(it => {
                const color =
                  it.state == 0
                    ? 'bg-yellow-600'
                    : it.state == 2
                    ? 'bg-red-500'
                    : 'bg-green-700'

                return (
                  <div className="flex" key={it.id} data-testid="history-item">
                    <div className="relative">
                      <div className="h-full w-6 flex items-center justify-center">
                        <div
                          className={`h-full w-1 ${color} pointer-events-none`}></div>
                      </div>
                      <div
                        className={`w-6 h-6 absolute top-1/2 -mt-3 rounded-full ${color} shadow text-center text-base`}>
                        {it.state == 0 ? '⏳' : it.state == 2 ? '😢' : '😊'}
                      </div>
                    </div>
                    <div
                      className={`${color} p-4 mx-3 rounded-xl my-4 shadow w-full`}>
                      <h3 className="text-base mb-1 uppercase">
                        {it.state == 0
                          ? 'pending'
                          : it.state == 2
                          ? 'rejected'
                          : 'approved'}
                      </h3>
                      <div className="font-semibold mb-2">
                        {it.previousValue} ➡ {it.value}
                      </div>
                      <div className="italic text-sm">
                        {`${it.comment ? `"${it.comment}"` : ''}`}
                      </div>
                      <p className="leading-tight text-justify w-full text-sm">
                        {it.updated &&
                          it.updated.split('T')[0] +
                            ' ' +
                            it.updated.split('T')[1].split('.')[0]}
                      </p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

interface Props {
  history: Record<string, HistoryItem[]>
}
