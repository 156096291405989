import React, { useEffect, useState } from 'react'
import { useAuthStore, useTranslationDataStore } from '../../store'
import { Language, Translation } from '../../types'
import { getApprovedValue } from '../../utils'
import { useLanguage } from '../hooks/useLanguage'
import { Button } from './Button'

interface Props {
  translation: Translation
  language: Language
  isEmpty: () => void
}

export const TextArea = (props: Props) => {
  const { user } = useAuthStore()
  const { translation, language, isEmpty } = props
  const [loading, setLoading] = useState(false)

  const { approve, dispatch, getText, flag, reject, isPending } = useLanguage({
    language,
    translation,
  })

  const { display, settings } = useTranslationDataStore()

  const [text, setText] = useState(() => getText())
  const [showReject, setShowReject] = useState(false)

  const rejectComment = () => (
    <div className="fixed flex justify-center items-center w-screen h-screen bg-black bg-opacity-70 top-0 left-0 text-gray-900">
      {/* <div className="bg-white p-3 rounded px-20 ">
        <div className="text-center text-4xl border-b">
          Add Comment (optional)
        </div>
        <input className="w-full border rounded p-2 my-3" />
        <Button
          title="Done"
          onClick={() => {
            setShowReject(false);
            reject();
          }}
        />
      </div> */}
    </div>
  )

  useEffect(() => {
    if (
      (display == 'missing' && getText()) ||
      (display == 'pending' && !isPending())
    ) {
      isEmpty()
    }
  })

  useEffect(() => {
    setLoading(false)
  }, [translation])

  return (
    <div className="mb-3 flex items-center">
      <img src={`/${flag}`} className="w-10 m-3 rounded shadow" />
      <textarea
        value={text}
        className={`text-gray-700 p-1 rounded w-full bg-gray-200 ${
          text === ''
            ? 'bg-red-200 border-red-600 border-2'
            : isPending() && 'bg-yellow-200 border-yellow-600 border-2'
        }`}
        onChange={e => {
          setText(e.target.value)
        }}
      />
      {isPending() &&
      language !== Language.german &&
      user?.rights.translator == 2 ? (
        <div className="p-3 gap-3 flex">
          <div className="w-44">
            <Button
              disabled={loading}
              title="Approve"
              onClick={() => {
                setLoading(true)
                approve()
              }}
              color="dark:bg-green-700 bg-green-500 dark:hover:bg-green-800 hover:bg-green-400"
            />
          </div>
          <div className="w-44">
            <Button
              disabled={loading}
              title="Reject"
              onClick={() => {
                setLoading(true)
                reject()
                setText(() => getApprovedValue(language, translation))
              }}
              color="dark:bg-red-700 bg-red-500 dark:hover:bg-red-800 hover:bg-red-400"
            />
          </div>
        </div>
      ) : (
        getText() !== text && (
          <div className="w-44 p-3">
            <Button
              disabled={loading}
              onClick={() => {
                setLoading(true)
                dispatch(text)
              }}
              title="Save"
              color="dark:bg-green-700 bg-green-500 dark:hover:bg-green-800 hover:bg-green-400"
            />
          </div>
        )
      )}
      {showReject && rejectComment()}
    </div>
  )
}
