import _ from 'lodash'
import { Language, Translation } from '../types'

export const createTreeObject = (
  input: Translation[],
): Record<string, unknown> => {
  const tree = {}
  input.map(it => {
    const keys = it.key.split('.')
    const object = keys.reverse().reduce(
      (acc, val): any => {
        return {
          [val]: acc,
          id: it.key.split(val)[0],
        }
      },
      { ...it, id: it.key },
    )
    _.merge(tree, object)
  })
  return tree
}

export const createMissingObject = (
  input: Translation[],
): Record<string, string[]> => {
  const object: Record<string, string[]> = {
    de: [],
    en: [],
    nl: [],
    ru: [],
    dk: [],
    ro: [],
  }
  input.map(it => {
    if (!it.value) {
      object.de.push(it.key)
    }
    if (
      !it.en &&
      !(it.history?.en && it.history?.en.find(it => it.state == 0))
    ) {
      object.en.push(it.key)
    }
    if (
      !it.nl &&
      !(it.history?.nl && it.history?.nl.find(it => it.state == 0))
    ) {
      object.nl.push(it.key)
    }
    if (
      !it.ru &&
      !(it.history?.ru && it.history?.ru.find(it => it.state == 0))
    ) {
      object.ru.push(it.key)
    }
    if (
      !it.dk &&
      !(it.history?.dk && it.history?.dk.find(it => it.state == 0))
    ) {
      object.dk.push(it.key)
    }
    if (
      !it.ro &&
      !(it.history?.ro && it.history?.ro.find(it => it.state == 0))
    ) {
      object.ro.push(it.key)
    }
  })
  return object
}

export const createPendingObject = (
  input: Translation[],
): Record<string, string[]> => {
  const object: Record<string, string[]> = {
    de: [],
    en: [],
    nl: [],
    ru: [],
    dk: [],
    ro: [],
  }
  input.map(it => {
    if (it.history?.en && it.history?.en.slice(-1)[0].state == 0) {
      object.en.push(it.key)
    }
    if (it.history?.nl && it.history?.nl.slice(-1)[0].state == 0) {
      object.nl.push(it.key)
    }
    if (it.history?.ru && it.history?.ru.slice(-1)[0].state == 0) {
      object.ru.push(it.key)
    }
    if (it.history?.dk && it.history?.dk.slice(-1)[0].state == 0) {
      object.dk.push(it.key)
    }
    if (it.history?.ro && it.history?.ro.slice(-1)[0].state == 0) {
      object.ro.push(it.key)
    }
  })
  return object
}
