import React, { useState } from 'react'
import { useTranslationDataStore } from '../../store'
import { Button } from './Button'

const Publish = () => {
  const { publish, projectSelected, togglePublish } = useTranslationDataStore()
  const [version, setVersion] = useState('')

  const post = () => {
    publish(version, projectSelected!.id)
  }

  const isValid = () => {
    const regex = /^\d+[.]\d+[.]\d+$/
    return regex.test(version)
  }

  return (
    <div className="absolute w-screen h-screen top-0 left-0 justify-center flex items-center bg-opacity-75 bg-black z-10">
      <div className="flex flex-col gap-3 w-2/3 dark:bg-gray-800 bg-gray-400 p-6 rounded">
        <div className="text-center text-2xl">Publish a new Version</div>
        <textarea
          className="rounded text-gray-700 p-1 w-full"
          placeholder="Version"
          value={version}
          onChange={e => setVersion(e.target.value)}
        />
        {!isValid() && (
          <div className="text-center italic">
            version needs to follow this pattern: x.y.z
          </div>
        )}
        <div className="flex gap-3">
          {version && isValid() && (
            <Button
              title="Publish"
              color="dark:bg-green-700 bg-green-500 dark:hover:bg-green-800 hover:bg-green-400"
              onClick={post}
            />
          )}

          <Button
            title="Cancel"
            color="bg-red-600 hover:bg-red-700"
            onClick={togglePublish}
          />
        </div>
      </div>
    </div>
  )
}

export default Publish
