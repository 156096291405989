import React, { useEffect } from 'react'
import { useAuthStore, useTranslationDataStore } from '../../store'
import { TreeView } from '../views/TreeView'
import { TranslationView } from '../views/TranslationView'
import SearchView from '../views/SearchView'
import { Header } from '../views/Header'
import { Loading } from '../views/Loading'
import ProjectView from '../views/ProjectView'
import { COMM_METHODS, TRANSLATIONS, USER_METHODS } from '../../api'
import { EmailView } from '../views/EmailView'
import { Error } from '../views/Error'
import { EmailState, Translation } from '../../types'

const Home = () => {
  const { error, projectSelected, search } = useTranslationDataStore()
  const fetchProjects = useTranslationDataStore(state => state.fetchProjects)
  const setTranslation = useTranslationDataStore(state => state.addTranslation)

  const { emailState, setEmailState, user } = useAuthStore()

  useEffect(() => {
    fetchProjects()
    const unsubcribe = handleSocket()
    checkEmail()
    return unsubcribe
  }, [])

  const handleSocket = () => {
    TRANSLATIONS.on('created', (result: Translation) => {
      setTranslation(result)
    })
    TRANSLATIONS.on('patched', (result: Translation) => {
      setTranslation(result)
    })

    return () => {
      TRANSLATIONS.removeAllListeners('ceated')
      TRANSLATIONS.removeAllListeners('patched')
    }
  }

  const checkEmail = async () => {
    const {
      data: [{ methodID = null } = {}],
    } = await USER_METHODS.find()

    if (!methodID) return setEmailState(EmailState.MISSING)

    const { type, isSecure, active } = await COMM_METHODS.get(methodID)

    if (type === 9 && isSecure) {
      setEmailState(active === 1 ? EmailState.PENDING : EmailState.DONE)
    }
  }

  const showView = () => {
    if (emailState != EmailState.DONE) return <EmailView />
    if (!projectSelected) return <ProjectView />
    if (search.length > 2) return <SearchView />
    return (
      <>
        <TreeView />
        <TranslationView />
      </>
    )
  }
  return (
    <div className="p-3">
      <Header />
      <div style={{ height: '75vh' }} className="px-10 pb-10 grid grid-cols-3">
        {showView()}
      </div>
      {error && <Error error={error} />}
    </div>
  )
}

export default Home
