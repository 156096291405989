import React, { useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useTranslationDataStore } from '../../store'
import { Language } from '../../types'
import { createMissingObject, createPendingObject } from '../../utils'
interface Props {
  object: any
  name: string
}

export const TreeView = () => {
  const { translationTree } = useTranslationDataStore()

  if (translationTree == null) return <></>

  return (
    <div className="col-span-1 mt-1 grid h-full">
      <Scrollbars style={{ height: '100%', width: '100%' }}>
        <div className="mr-3">
          {Object.entries(translationTree).map(([key, values]) => (
            <TreeNode key={key} name={key} object={values} />
          ))}
        </div>
      </Scrollbars>
    </div>
  )
}

const TreeNode = (props: Props) => {
  const { name, object } = props
  const [open, setOpen] = useState(false)
  const { selection, settings, display, translation } =
    useTranslationDataStore()
  const setSelection = useTranslationDataStore(state => state.setSelection)

  const getMissing = (): boolean => {
    if (!translation || !(object && object.id)) return false
    const missing = createMissingObject(translation)
    if (
      settings.languageSelected.includes(Language.german) &&
      missing.de.find(it => it.startsWith(object.id))
    )
      return true
    if (
      settings.languageSelected.includes(Language.english) &&
      missing.en.find(it => it.startsWith(object.id))
    )
      return true
    if (
      settings.languageSelected.includes(Language.dutch) &&
      missing.nl.find(it => it.startsWith(object.id))
    )
      return true
    if (
      settings.languageSelected.includes(Language.russian) &&
      missing.ru.find(it => it.startsWith(object.id))
    )
      return true
    if (
      settings.languageSelected.includes(Language.danish) &&
      missing.dk.find(it => it.startsWith(object.id))
    )
      return true
    if (
      settings.languageSelected.includes(Language.romanian) &&
      missing.ro.find(it => it.startsWith(object.id))
    )
      return true
    return false
  }

  const getPending = (): boolean => {
    if (!translation || !(object && object.id)) return false
    const missing = createPendingObject(translation)
    if (
      settings.languageSelected.includes(Language.german) &&
      missing.de.find(it => it.startsWith(object.id))
    )
      return true
    if (
      settings.languageSelected.includes(Language.english) &&
      missing.en.find(it => it.startsWith(object.id))
    )
      return true
    if (
      settings.languageSelected.includes(Language.dutch) &&
      missing.nl.find(it => it.startsWith(object.id))
    )
      return true
    if (
      settings.languageSelected.includes(Language.russian) &&
      missing.ru.find(it => it.startsWith(object.id))
    )
      return true
    return false
  }

  if (!object || !object.id) return <></>

  const handleClick = () => {
    setSelection(object.id.slice(0, -1))
    setOpen(!open)
  }

  if (
    display === 'all' ||
    (getPending() && display !== 'missing') ||
    (getMissing() && display !== 'pending')
  )
    return (
      <div
        className={`my-2 ml-0 mr-0 rounded dark:hover:bg-black hover:bg-gray-600 transition-colors ${
          selection && object.id.startsWith(selection)
            ? 'dark:bg-gray-900 bg-gray-500'
            : 'dark:bg-gray-700 bg-gray-400'
        }`}>
        <div
          data-testid="tree-node"
          onClick={handleClick}
          className={`flex cursor-pointer p-3 text-xl dark:text-gray-100 capitalize`}>
          {name}
          <div className="m-auto" />
          <div className="flex gap-1 h-5 items-center ">
            {getPending() && display !== 'missing' && (
              <div className="flex rounded-full justify-center bg-yellow-500 text-sm lowercase shadow-md w-5 h-5">
                !
              </div>
            )}
            {getMissing() && display !== 'pending' && (
              <div className="flex rounded-full justify-center bg-red-500 text-sm lowercase shadow-md w-5 h-5">
                !
              </div>
            )}
          </div>
        </div>
        {open && (
          <div className="dark:bg-gray-800 bg-gray-300 ml-2 pl-2  flex flex-col rounded-tl">
            {Object.entries(object)
              .sort((a, b) => (a[0] < b[0] ? -1 : 1))
              .map(([key, values]) => (
                <TreeNode key={key} name={key} object={values} />
              ))}
          </div>
        )}
      </div>
    )
  return null
}
