import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { useAuthStore } from './store'
import Login from '../src/components/screens/Login'
import Home from './components/screens/Home'
import * as Sentry from '@sentry/react'
import { Loading } from './components/views/Loading'

function App() {
  const { user, reAuth, loading } = useAuthStore()

  useEffect(() => {
    reAuth()
  }, [])

  return (
    <BrowserRouter basename={import.meta.env.BASE_URL}>
      <Switch>
        {loading ? (
          <></>
        ) : user ? (
          <>
            {Sentry.setUser({
              username: user.username,
              id: user.id.toString(),
            })}
            <Route exact path="/" component={Home} />
          </>
        ) : (
          <>
            {Sentry.setUser({ username: 'not logged in' })}
            <Route path="/" component={Login} />
          </>
        )}
      </Switch>
    </BrowserRouter>
  )
}

export default App
