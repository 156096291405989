import React, { useState } from 'react'
import { useTranslationDataStore } from '../../store'
import { Translation } from '../../types'

interface Props {
  translation: Translation
}

export const KeyInput = (props: Props) => {
  const [key, setKey] = useState(props.translation.key)
  const [width, setWith] = useState(props.translation.key.length)
  const { changeValue, translation } = useTranslationDataStore()

  const isKeyUnique = (key: string) => {
    if (key.length < 1) return false
    return !translation?.find(it => it.key === key)
  }

  return (
    <div className="flex mb-1">
      <input
        style={{ width: `${width}ch` }}
        value={key}
        onChange={e => {
          setKey(e.target.value)
          setWith(e.target.value.length)
        }}
        placeholder="input key"
        className="bg-transparent text-sm w-min"
      />
      {key !== props.translation.key && isKeyUnique(key) && (
        <button
          className="text-sm mx-4 bg-green-500 px-3 rounded"
          onClick={() => {
            const newTrans = props.translation
            newTrans.key = key
            changeValue(newTrans)
          }}>
          Save
        </button>
      )}
      {props.translation.value}
    </div>
  )
}
