import React, { useEffect, useState } from 'react'
import { useAuthStore, useTranslationDataStore } from '../../store'
import { Translation } from '../../types'
import { History } from './History'
import { KeyInput } from './KeyInput'
import { TextArea } from './TextArea'

export const TranslationItem = (props: Props) => {
  const { it } = props
  const { settings, display } = useTranslationDataStore()

  const { user } = useAuthStore()

  const [history, setHistory] = useState(false)
  const [empty, setEmpty] = useState<string[]>([])

  useEffect(() => {
    setEmpty([])
  }, [display])

  const displayTextArea = (translation: Translation) => {
    const items = settings.languageSelected.map(language => (
      <TextArea
        key={translation.key + language}
        translation={translation}
        language={language}
        isEmpty={() => {
          if (!empty.includes(translation.key + language)) {
            setEmpty([...empty, translation.key + language])
          }
        }}
      />
    ))
    return items
  }

  if (empty.length == settings.languageSelected.length) return null

  return (
    <div
      key={it.key}
      className="flex flex-col mb-3 dark:bg-gray-700 bg-gray-400 rounded p-3 shadow dark:text-gray-100">
      <div
        className="text-sm self-end cursor-pointer hover:bg-gray-800 px-3 rounded"
        onClick={() => setHistory(!history)}>
        {history ? '🏳‍🌈 Translation' : '🗓 History'}
      </div>
      {user!.rights.translator == 2 ? (
        <KeyInput translation={it} />
      ) : (
        <span className="text-base">
          <i className="text-sm">{it.key}</i> {it.value}
        </span>
      )}
      {history ? <History history={it.history} /> : displayTextArea(it)}
    </div>
  )
}

interface Props {
  it: Translation
}
