import React, { useState } from 'react'
import { COMM_METHODS } from '../../api'
import { useAuthStore } from '../../store'
import { EmailState } from '../../types'
import { Button } from './Button'

export const EmailView = () => {
  const { emailState, setEmailState, user } = useAuthStore()
  const [email, setEmail] = useState('')
  const regex = /\S+@\S+\.\S+/

  const sendEmail = async () => {
    await COMM_METHODS.create({
      value: email,
      name: user?.username,
      secure: true,
      type: 9,
    })
    setEmailState(EmailState.PENDING)
  }

  if (emailState == EmailState.PENDING)
    return (
      <div className="items-center w-full justify-center flex mt-3 col-span-3">
        <div className="rounded p-10 dark:bg-gray-700 bg-gray-400 flex flex-col">
          <div
            className="dark:text-gray-100 text-2xl border-b pb-2 mb-2 border-gray-500 w-full text-center"
            data-testid="pending">
            Please confirm your email!
          </div>
          <div className="dark:text-gray-100">
            Check {email ? email : 'your email'}, open the link and refresh
          </div>
          <div className="flex justify-center text-2xl my-5">
            <div className="animate-ping">💌</div>
          </div>
        </div>
      </div>
    )

  return (
    <div className="items-center w-full justify-center flex mt-3 col-span-3">
      <div className="rounded p-10 dark:bg-gray-700 bg-gray-400 flex flex-col">
        <div className="dark:text-gray-100 text-2xl border-b pb-2 mb-2 border-gray-500 w-full text-center">
          Please add and confirm your email!
        </div>
        <input
          data-testid="email-input"
          value={email}
          placeholder="email"
          className="text-gray-700 p-1 rounded w-full mt-3"
          onChange={({ target }) => setEmail(target.value)}
        />
        {!regex.test(email) && (
          <div
            className="italic dark:text-gray-100 font-thin text-sm"
            data-testid="error-message">
            please enter a valid email address
          </div>
        )}
        <div className="mb-3" />
        {regex.test(email) && (
          <Button title="Send" onClick={() => sendEmail()} />
        )}
      </div>
    </div>
  )
}
