import { useTranslationDataStore } from '../../store'
import { Language, Translation } from '../../types'
import { getPending } from '../../utils'

export const useLanguage = (props: Props) => {
  const { changeTranslation, approve, reject, changeValue } =
    useTranslationDataStore()

  const { language, translation } = props

  const CONSTANT = {
    [Language.german]: {
      flag: 'germany.svg',
      name: 'German',
      getText: () => translation!.value,
      approve: () => null,
      reject: () => null,
      dispatch: (text: string) => {
        if (!translation) return
        const newTrans = translation
        newTrans.value = text
        changeValue(newTrans)
      },
      isPending: () => false,
    },

    [Language.english]: {
      flag: 'uk.svg',
      name: 'English',
      getText: () =>
        getPending(translation!.history?.en) || translation!.en || '',
      approve: () => {
        const change = translation!.history?.en?.find(it => it.state == 0)
        if (!change) return
        approve(change!.id, translation!.projectID, change.value)
      },
      reject: () => {
        const change = translation!.history?.en?.find(it => it.state == 0)
        if (!change) return
        reject(change!.id, translation!.projectID, change.value)
      },
      dispatch: (text: string) => {
        changeTranslation(translation!.projectID, translation!.id, 'en', text)
      },
      isPending: () => getPending(translation!.history?.en) !== null,
    },

    [Language.dutch]: {
      flag: 'netherlands.svg',
      name: 'Dutch',
      getText: () =>
        getPending(translation!.history?.nl) || translation!.nl || '',
      approve: () => {
        const change = translation!.history?.nl?.find(it => it.state == 0)
        if (!change) return
        approve(change!.id, translation!.projectID, change.value)
      },
      reject: () => {
        const change = translation!.history?.nl?.find(it => it.state == 0)
        if (!change) return
        reject(change!.id, translation!.projectID, change.value)
      },
      dispatch: (text: string) => {
        changeTranslation(translation!.projectID, translation!.id, 'nl', text)
      },
      isPending: () => getPending(translation!.history?.nl) !== null,
    },

    [Language.russian]: {
      flag: 'russia.svg',
      name: 'Russian',
      getText: () =>
        getPending(translation!.history?.ru) || translation!.ru || '',
      approve: () => {
        const change = translation!.history?.ru?.find(it => it.state == 0)
        if (!change) return
        approve(change!.id, translation!.projectID, change.value)
      },
      reject: () => {
        const change = translation!.history?.ru?.find(it => it.state == 0)
        if (!change) return
        reject(change!.id, translation!.projectID, change.value)
      },
      dispatch: (text: string) => {
        changeTranslation(translation!.projectID, translation!.id, 'ru', text)
      },
      isPending: () => getPending(translation!.history?.ru) !== null,
    },

    [Language.romanian]: {
      flag: 'romania.svg',
      name: 'Romanian',
      getText: () =>
        getPending(translation!.history?.ro) || translation!.ro || '',
      approve: () => {
        const change = translation!.history?.ro?.find(it => it.state == 0)
        if (!change) return
        approve(change!.id, translation!.projectID, change.value)
      },
      reject: () => {
        const change = translation!.history?.ro?.find(it => it.state == 0)
        if (!change) return
        reject(change!.id, translation!.projectID, change.value)
      },
      dispatch: (text: string) => {
        changeTranslation(translation!.projectID, translation!.id, 'ro', text)
      },
      isPending: () => getPending(translation!.history?.ro) !== null,
    },

    [Language.danish]: {
      flag: 'denmark.svg',
      name: 'Danish',
      getText: () =>
        getPending(translation!.history?.dk) || translation!.dk || '',
      approve: () => {
        const change = translation!.history?.dk?.find(it => it.state == 0)
        if (!change) return
        approve(change!.id, translation!.projectID, change.value)
      },
      reject: () => {
        const change = translation!.history?.dk?.find(it => it.state == 0)
        if (!change) return
        reject(change!.id, translation!.projectID, change.value)
      },
      dispatch: (text: string) => {
        changeTranslation(translation!.projectID, translation!.id, 'dk', text)
      },
      isPending: () => getPending(translation!.history?.dk) !== null,
    },
  }

  return CONSTANT[language]
}

interface Props {
  language: Language
  translation?: Translation
}
