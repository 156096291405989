import React, { useRef } from 'react'
import { useAuthStore } from '../../store'

const Login = () => {
  const { login, error } = useAuthStore()
  const username = useRef<HTMLInputElement>(null)
  const password = useRef<HTMLInputElement>(null)

  const handleLogin = () => {
    if (username.current && password.current) {
      login({
        username: username.current?.value,
        password: password.current?.value,
      })
    }
  }

  return (
    <div className="w-screen h-screen justify-center items-center flex bg-gray-300">
      <div className="flex bg-white flex-col p-10 rounded w-1/3">
        <img
          src="https://notify-prod-v3.casenio.de//static/media/casenio_2020.610c87cb.svg"
          alt=""
          className=""
        />
        {error && (
          <div className="bg-red-600 p-2 mx-3 rounded text-gray-100">
            Wrong credentials supplied! Check your username and password
          </div>
        )}
        <form className="flex flex-col" onSubmit={e => e.preventDefault()}>
          <input
            ref={username}
            placeholder="Username"
            className="border p-3 m-3"
          />
          <input
            ref={password}
            placeholder="Password"
            className="border p-3 m-3"
            type="password"
          />
          <button
            className="p-3 bg-green-600 rounded m-3 text-gray-100 text-xl"
            onClick={handleLogin}
            type="submit">
            Login
          </button>
        </form>
      </div>
    </div>
  )
}

export default Login
