import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'

import App from './App'
import { version } from '../package.json'

import 'tailwindcss/tailwind.css'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://15c4b3ca58ec44f5a32b79ff4a9b3ad1@sentry.casenio.de/37',
    environment: process.env.NODE_ENV,
    ignoreErrors: ['NotAuthenticated'],
    release: version,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <div className="dark:bg-gray-800 bg-gray-300 h-screen overflow-hidden">
      <App />
    </div>
  </React.StrictMode>,
  document.getElementById('root'),
)
