import create from 'zustand'
import { login, reAuth, logout } from '../api'
import { AuthPayload, EmailState, User } from '../types'

export const useAuthStore = create<UserState>(set => ({
  loading: true,
  user: null,
  error: undefined,
  emailState: EmailState.DONE,
  login: async payload => {
    try {
      const user = await login(payload)
      set({ user, error: undefined })
    } catch (error) {
      set({ error: Error('Error logging in') })
    }
  },
  logout: async () => {
    await logout()
    set({ user: null, error: undefined, emailState: EmailState.MISSING })
  },
  reAuth: async () => {
    set({ loading: true })
    const user = await reAuth()
    set({ user })
    set({ loading: false })
  },
  setEmailState: (state: EmailState) => set(() => ({ emailState: state })),
}))

interface UserState {
  loading: boolean
  user: User | null
  emailState: EmailState
  error: Error | undefined
  login: (payload: AuthPayload) => void
  logout: () => void
  reAuth: () => void
  setEmailState: (state: EmailState) => void
}
