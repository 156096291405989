import { Translation, Project, Language } from '../types'
import {
  GENERATE,
  PROJECTS,
  TRANSLATIONS,
  HISTORY,
  API_ENV,
  API_ENDPOINT,
} from './feathers'

export const fetchTranslation = async (
  projectID: number,
): Promise<Translation[] | Error> => {
  const options = {
    query: {
      projectID,
      $limit: 3000,
    },
  }
  const [res, error] = await handle(TRANSLATIONS.find(options))
  if (error) return Error(error.message)
  return res.data
}

export const patchValue = async (
  translation: Translation,
): Promise<Translation | Error> => {
  const [res, error] = await handle(
    TRANSLATIONS.patch(translation.id, { ...translation }),
  )
  if (error) return Error(error.message)
  console.log(res)
  return res
}

export const patchTranslation = async (
  projectID: number,
  translationID: number,
  lng: string,
  value: string,
): Promise<Error | Translation> => {
  const [res, error] = await handle(
    HISTORY.create({
      projectID,
      translationID,
      lng,
      value,
    }),
  )
  if (error) return Error(error.message)
  return res
}

export const fetchProjects = async (): Promise<Project[] | Error> => {
  const [res, error] = await handle(PROJECTS.find({ query: { visible: true } }))
  if (error) return Error(error.message)
  return responseToProjects(res.data)
}

export const postNewTranslation = async (
  translation: Record<string, string>,
): Promise<Translation | Error> => {
  const [res, error] = await handle(TRANSLATIONS.create(translation))
  if (error) return Error(error.message)
  return res
}

export const publish = async (
  version: string,
  projectID: number,
): Promise<undefined | Error> => {
  const [res, error] = await handle(GENERATE.create({ version, projectID }))
  if (error) return Error(error.message)
}

export const reject = async (
  id: number,
  value: string,
): Promise<Translation | Error> => {
  const [res, error] = await handle(HISTORY.patch(id, { state: 2, value }))
  if (error) return Error(error.message)
  return res
}

export const approve = async (
  id: number,
  value: string,
): Promise<Translation | Error> => {
  const [res, error] = await handle(HISTORY.patch(id, { state: 1, value }))
  if (error) return Error(error.message)
  return res
}

const responseToProjects = (
  input: Array<Record<string, string>>,
): Project[] => {
  return input.map(it => {
    return {
      id: parseInt(it.id),
      desc: it.description,
      name: it.name,
      languages: languageStringToArray(it.languages),
    }
  })
}

const languageStringToArray = (input: string): Language[] => {
  return input.split(',').map(it => {
    switch (it) {
      case 'de':
        return Language.german
      case 'en':
        return Language.english
      case 'nl':
        return Language.dutch
      case 'ru':
        return Language.russian
      case 'ro':
        return Language.romanian
      case 'dk':
        return Language.danish
      default:
        throw Error('Language not supported')
    }
  })
}

export const notify = async (projectID: number): Promise<undefined | Error> => {
  const [res, error] = await handle(
    fetch(`${API_ENDPOINT}/i18n-notify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({ projectID }),
    }),
  )
  console.log(res)
  if (error) return Error(error.message)
}

const handle = async (promise: Promise<any>): Promise<any[]> => {
  try {
    const data = await promise
    return [data, undefined]
  } catch (err) {
    return [undefined, err]
  }
}
