import React, { useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useTranslationDataStore } from '../../store'
import { TranslationItem } from './TranslationItem'

export const TranslationView = () => {
  const { translation, selection, settings } = useTranslationDataStore()

  if (translation == null) return <></>

  const lngSelected = settings.languageSelected.length
  const keySelected = selection.length

  if (!lngSelected || !keySelected)
    return (
      <div className="p-10 text-center dark:text-gray-100 text-3xl col-span-2">
        {`Please select ${
          !lngSelected ? 'some languages' : 'a category to start translating'
        }.`}
      </div>
    )

  return (
    <Scrollbars
      className=" my-3 p-3 text-xl dark:text-gray-100 rounded col-span-2"
      style={{ height: '100%', width: '100%' }}>
      <div className="pr-3 ml-2">
        {translation
          .sort((a, b) => (a.id < b.id ? -1 : 1))
          .map(it => {
            if (it.key.startsWith(selection)) {
              return <TranslationItem it={it} key={it.key} />
            }
          })}
      </div>
    </Scrollbars>
  )
}
