import { sha512 } from 'js-sha512'
import { AuthPayload, User } from '../types'

import { app } from './feathers'

export const reAuth = async (): Promise<User | null> => {
  try {
    const { user } = await app.reAuthenticate()
    return user
  } catch (error) {
    return null
  }
}

export const login = async (payload: AuthPayload): Promise<User> => {
  const { user } = await app.authenticate({
    ...payload,
    password: sha512(payload.password),
    strategy: 'i18n',
  })
  return user
}

export const logout = async () => await app.logout()
