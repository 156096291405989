import { HistoryItem, Language, Translation, User } from '../types'

export const getApprovedValue = (
  language: Language,
  translation: Translation,
): string => {
  const value =
    language == Language.english
      ? translation.en
      : language == Language.dutch
      ? translation.nl
      : language == Language.russian
      ? translation.ru
      : language == Language.romanian
      ? translation.ro
      : language == Language.danish
      ? translation.dk
      : ''

  if (!value) return ''
  return value
}

export const stringToFlag = (language: string) =>
  language == 'nl'
    ? 'netherlands.svg'
    : language == 'en'
    ? 'uk.svg'
    : language == 'ru'
    ? 'russia.svg'
    : language == 'dk'
    ? 'denmark.svg'
    : language == 'ro'
    ? 'romania.svg'
    : new Error('language unknown')

export const getMissingNr = (
  translation: Translation[],
  language: Language[],
  search: string,
): number => {
  let counter = 0
  translation?.map(it => {
    if (language.includes(Language.german) && !it.value) counter++
    if (
      language.includes(Language.english) &&
      !it.en &&
      !(it.history?.en && it.history?.en.find(it => it.state == 0))
    )
      counter++
    if (
      language.includes(Language.dutch) &&
      !it.nl &&
      !(it.history?.nl && it.history?.nl.find(it => it.state == 0))
    )
      counter++
    if (
      language.includes(Language.russian) &&
      !it.ru &&
      !(it.history?.ru && !it.history?.ru.find(it => it.state == 0))
    )
      counter++
    if (
      language.includes(Language.danish) &&
      !it.dk &&
      !(it.history?.dk && !it.history?.dk.find(it => it.state == 0))
    )
      counter++
    if (
      language.includes(Language.romanian) &&
      !it.ro &&
      !(it.history?.ro && !it.history?.ro.find(it => it.state == 0))
    )
      counter++
  })
  return counter
}
export const getPendingNr = (
  translation: Translation[],
  language: Language[],
  search: string,
): number => {
  let counter = 0
  translation?.map(it => {
    if (
      language.includes(Language.english) &&
      it.history?.en &&
      it.history?.en.find(it => it.state == 0)
    )
      counter++
    if (
      language.includes(Language.dutch) &&
      it.history?.nl &&
      it.history?.nl.find(it => it.state == 0)
    )
      counter++
    if (
      language.includes(Language.russian) &&
      it.history?.ru &&
      it.history?.ru.find(it => it.state == 0)
    )
      counter++
  })
  return counter
}

export const getPending = (history?: HistoryItem[]): string | null => {
  if (!history) return null
  const lastState = history.slice(-1)[0].state
  if (lastState == 0) return history.slice(-1)[0].value
  return null
}

export const allowedLanguages = (user: User | null): Language[] => {
  const result: Language[] = []
  if (!user) return []
  Object.entries(user.rights).map(([key, value]) => {
    if (key === 'i18n-dansk' && value != 0) result.push(Language.danish)
    if (key === 'i18n-dutch' && value != 0) result.push(Language.dutch)
    if (key === 'i18n-english' && value != 0) result.push(Language.english)
    if (key === 'i18n-romanian' && value != 0) result.push(Language.romanian)
    if (key === 'i18n-russian' && value != 0) result.push(Language.russian)
  })
  return result
}
