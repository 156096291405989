import React, { useState } from 'react'
import { useTranslationDataStore } from '../../store'
import { Language } from '../../types'
import { Button } from './Button'

const AddString = () => {
  const { toggleAddString, postNew, projectSelected, translation } =
    useTranslationDataStore()
  const [key, setKey] = useState('')
  const [value, setValue] = useState('')
  const [context, setContext] = useState('')
  const [en, setEn] = useState('')
  const [nl, setNl] = useState('')
  const [ru, setRu] = useState('')
  const [ro, setRo] = useState('')

  const post = () => {
    postNew({
      key,
      context,
      value,
      en,
      nl,
      ru,
      ro,
      projectID: projectSelected!.id,
      static: null,
      type: null,
    })
  }

  const isKeyUnique = (key: string) => {
    return !translation?.find(it => it.key === key)
  }

  return (
    <div className="absolute w-screen h-screen top-0 left-0 justify-center flex items-center bg-opacity-75 bg-black z-10">
      <div className="flex flex-col gap-3 w-2/3 dark:bg-gray-800 bg-gray-400 p-6 rounded">
        <div className="text-center text-2xl">Add a new String</div>
        <div
          className="text-center text-red-500 italic"
          data-testid="error-message">
          {!value && 'value is missing'}{' '}
          {!value && !(isKeyUnique(key) && key) && 'and'}{' '}
          {!isKeyUnique(key) ? 'key is not unique' : !key && 'key is missing'}
        </div>
        <textarea
          className="rounded text-gray-700 p-1 w-full"
          placeholder="Key"
          data-testid="key-input"
          value={key}
          onChange={e => setKey(e.target.value)}
        />
        <textarea
          className="rounded text-gray-700 p-1 w-full"
          placeholder="Value (DE)"
          data-testid="value-input"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <textarea
          className="rounded text-gray-700 p-1 w-full"
          placeholder="Context"
          value={context}
          onChange={e => setContext(e.target.value)}
        />
        {projectSelected?.languages.includes(Language.english) && (
          <textarea
            className="rounded text-gray-700 p-1 w-full"
            placeholder="EN"
            value={en}
            onChange={e => setEn(e.target.value)}
          />
        )}

        {projectSelected?.languages.includes(Language.dutch) && (
          <textarea
            className="rounded text-gray-700 p-1 w-full"
            placeholder="NL"
            value={nl}
            onChange={e => setNl(e.target.value)}
          />
        )}
        {projectSelected?.languages.includes(Language.russian) && (
          <textarea
            className="rounded text-gray-700 p-1 w-full"
            placeholder="RU"
            value={ru}
            onChange={e => setRu(e.target.value)}
          />
        )}
        <div className="flex gap-3" data-testid="button-wrapper">
          {key && value && isKeyUnique(key) && (
            <Button
              title="Save"
              color="dark:bg-green-700 bg-green-500 dark:hover:bg-green-800 hover:bg-green-400"
              onClick={post}
            />
          )}
          <Button
            title="Cancel"
            color="bg-red-600 hover:bg-red-700"
            onClick={toggleAddString}
          />
        </div>
      </div>
    </div>
  )
}

export default AddString
