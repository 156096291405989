import React, { useState } from 'react'
import { useTranslationDataStore } from '../../store'
import { Loading } from './Loading'
import { Scrollbars } from 'react-custom-scrollbars'
import { v4 as uuidv4 } from 'uuid'
import { TranslationItem } from './TranslationItem'
import { getApprovedValue, getPending } from '../../utils'
import { Translation, Language } from '../../types'

const SearchView = () => {
  const { translation, settings, search } = useTranslationDataStore()

  if (!translation) {
    return <Loading />
  }

  return (
    <div className="col-span-3 mt-5">
      <Scrollbars style={{ width: '100%', height: '100%' }}>
        {settings.languageSelected.length > 0 ? (
          translation
            .sort((a, b) => (a.id < b.id ? -1 : 1))
            .map(it => {
              if (isSearch(it, settings.languageSelected, search)) {
                return <TranslationItem it={it} key={it.id} />
              } else {
                return <React.Fragment key={uuidv4()}></React.Fragment>
              }
            })
        ) : (
          <div className="p-10 col-span-3 text-center dark:text-gray-100 text-3xl">
            Select languages to search by
          </div>
        )}
      </Scrollbars>
    </div>
  )
}

export default SearchView

const isSearch = (
  translation: Translation,
  languages: Language[],
  search: string,
): boolean => {
  let value = false
  languages.map(it => {
    if (
      translation.key.includes(search.toLowerCase()) ||
      translation.value.toLowerCase().includes(search.toLowerCase()) ||
      getApprovedValue(it, translation)
        .toLowerCase()
        .includes(search.toLowerCase())
    ) {
      value = true
    }
  })

  return value
}
