import React, { useEffect } from 'react'
import { useTranslationDataStore } from '../../store'
import { Loading } from './Loading'
import { ProjectTile } from './ProjectTile'

const ProjectView = () => {
  const { projects } = useTranslationDataStore()
  const fetchProjects = useTranslationDataStore(state => state.fetchProjects)

  useEffect(() => {
    fetchProjects()
  }, [])

  return (
    <div className="grid mt-3 w-full gap-3 grid-cols-3 col-span-3 auto-rows-min">
      {projects.length == 0 ? (
        <Loading />
      ) : (
        projects.map(it => <ProjectTile key={it.id} project={it} />)
      )}
    </div>
  )
}

export default ProjectView
