import React from 'react'

interface Props {
  title: string
  onClick?: () => void
  color?: string
  disabled?: boolean
  testid?: string
}

export const Button = (props: Props) => {
  return (
    <button
      data-testid={`${props.testid ? props.testid : 'button'}`}
      disabled={props.disabled}
      className={`p-2 w-full ${
        props.color ? props.color : 'dark:bg-gray-900 dark:hover:bg-black'
      } rounded transition dark:text-gray-100 text-xl font-sans shadow bg-gray-300 hover:bg-gray-200`}
      onClick={props.onClick}>
      {props.title}
    </button>
  )
}
