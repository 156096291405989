import React, { Fragment } from 'react'
import { useAuthStore, useTranslationDataStore } from '../../store'
import { allowedLanguages, getMissingNr, getPendingNr } from '../../utils'
import AddString from './AddString'
import { Button } from './Button'
import { LanguageSelect } from './LanguageSelect'
import Publish from './Publish'
import * as _package from '../../../package.json'
import { Language } from '../../types'

export const Header = () => {
  const {
    settings,
    translation,
    projectSelected,
    translationTree,
    isAddString,
    display,
    isPublish,
    notify,
    showNotify,
    search,
  } = useTranslationDataStore()

  const { user } = useAuthStore()

  const logout = useAuthStore(state => state.logout)
  const setDisplay = useTranslationDataStore(state => state.setDisplay)
  const setSearch = useTranslationDataStore(state => state.setSearch)
  const reset = useTranslationDataStore(state => state.reset)
  const setIsDark = useTranslationDataStore(state => state.setIsDark)
  const toggleAddString = useTranslationDataStore(
    state => state.toggleAddString,
  )
  const togglePublish = useTranslationDataStore(state => state.togglePublish)
  const { languageSelected: language, isDark } = settings

  return (
    <div
      className="grid grid-cols-2 gap-3 dark:bg-gray-700 bg-gray-400 rounded-lg mx-10 p-3 dark:text-gray-100 z-10"
      data-testid="wrapper">
      <div className="text-5xl font-semibold col-span-2 text-center font-serif border-b pb-2 mb-2 border-gray-500">
        CASENIO - I18N
        <div className="text-xs font-thin font-sans opacity-30 leading-none">
          {_package.version}
        </div>
        <div className="text-xl font-sans font-thin">
          {projectSelected?.name}
        </div>
        <div className="flex w-52 absolute top-5 left-16">
          <div
            className="text-xl p-1 bg-gray-900 dark:bg-gray-300 rounded-full m-1 cursor-pointer select-none "
            onClick={() => {
              const root = document.getElementById('root')
              if (isDark) {
                root?.classList.remove('dark')
              } else {
                root?.classList.add('dark')
              }
              setIsDark(!isDark)
            }}>
            {isDark ? (
              <img src="https://img.icons8.com/color/32/000000/yoda.png" />
            ) : (
              <img src="https://img.icons8.com/color/32/000000/darth-vader.png" />
            )}
          </div>
        </div>
        <div className="flex w-52 absolute top-5 right-16">
          {projectSelected ? (
            <Button
              title="Back"
              color="bg-red-600 hover:bg-red-700"
              onClick={() => reset()}
            />
          ) : (
            <Button
              title="Logout"
              onClick={() => {
                logout()
                reset()
              }}
              color="bg-red-600 hover:bg-red-700"
            />
          )}
        </div>
      </div>

      {projectSelected && translationTree && (
        <>
          <div className="flex col-span-1 justify-center text-xl items-center transition bg-gray-900 mx-auto p-2 rounded">
            <div
              className={`${
                display == 'all' && 'dark:bg-green-600 bg-green-500 shadow '
              } mx-1 hover:bg-green-500 p-1 px-3 rounded cursor-pointer transition-colors select-none text-white`}
              onClick={() => {
                setDisplay('all')
              }}
              data-testid="all">
              {translation
                ? ` All: ${
                    translation.length * settings.languageSelected.length
                  }`
                : 'Loading ...'}
            </div>
            <div
              className={`${
                display == 'pending' &&
                'dark:bg-yellow-600 bg-yellow-500 shadow '
              } mx-1 hover:bg-yellow-500 p-1 px-3 rounded cursor-pointer transition-colors select-none text-white`}
              onClick={() => {
                setDisplay('pending')
              }}
              data-testid="pending">
              {translation
                ? ` Pending: ${getPendingNr(translation, language, search)}`
                : 'Loading ...'}
            </div>
            <div
              className={`${
                display == 'missing' && 'dark:bg-red-500 bg-red-500 shadow'
              }  mx-1 hover:bg-red-500 p-1 px-3 rounded cursor-pointer transition-colors select-none text-white`}
              onClick={() => {
                setDisplay('missing')
              }}
              data-testid="missing">
              {translation
                ? ` Missing: ${getMissingNr(translation, language, search)}`
                : 'Loading ...'}
            </div>
          </div>

          <div className="flex col-span-1 justify-center text-xl items-center space-x-2">
            {!(user!.rights.translator == 2) && showNotify && (
              <>
                <Button
                  title="Notifiy Approver"
                  onClick={() => notify(projectSelected.id)}
                />
              </>
            )}
            {user!.rights.translator == 2 && (
              <>
                <Button
                  title="Publish"
                  onClick={togglePublish}
                  testid="publish-button"
                />
                <Button
                  title="Add String"
                  onClick={toggleAddString}
                  testid="add-string-button"
                />
                {showNotify && (
                  <Button
                    title="Notifiy Translator"
                    onClick={() => notify(projectSelected.id)}
                    testid="notify-button"
                  />
                )}
              </>
            )}
          </div>

          <div
            className="flex col-span-1 justify-center text-xl items-center"
            data-testid="language-wrapper">
            {allowedLanguages(user).map(it => {
              if (it == Language.german && !(user!.rights.translator == 2))
                return <Fragment key={it}></Fragment>
              return <LanguageSelect key={it} language={it} />
            })}
          </div>

          <div className="flex col-span-1 justify-center text-xl items-center">
            <input
              data-testid="search-bar"
              className="rounded w-full text-gray-700 p-1"
              placeholder="Search..."
              onChange={e => setSearch(e.target.value)}
            />
          </div>
        </>
      )}
      {isAddString && <AddString />}
      {isPublish && <Publish />}
    </div>
  )
}
