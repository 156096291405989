import React, { useState, useRef } from 'react'
import { useTranslationDataStore } from '../../store'
import { Language } from '../../types'
import { useLanguage } from '../hooks/useLanguage'

interface Props {
  language: Language
}

export const LanguageSelect = (props: Props) => {
  const setLanguage = useTranslationDataStore(state => state.selectLanguage)
  const { settings } = useTranslationDataStore()
  const languages = settings.languageSelected
  const { language } = props
  const { name } = useLanguage({ language })

  return (
    <div
      className={`items-center mx-2 p-1 px-3 rounded-full transition cursor-pointer ${
        languages.includes(language) && 'dark:bg-gray-900 bg-gray-300 shadow '
      } dark:hover:bg-black hover:bg-gray-200`}
      onClick={() => {
        setLanguage(language, !languages.includes(language))
      }}>
      <input type="checkbox" readOnly checked={languages.includes(language)} />
      {` ${name}`}
    </div>
  )
}
